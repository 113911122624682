import * as React from 'react';

import IndexLayout from '../../layouts';

import Footer from '../../components/Footer/Footer';
import SEO from '../../components/SEO/SEO';

import EcommerceFeatures from '../../components/Industries/Ecommerce/EcommerceFeatures/EcommerceFeatures';
import EcommerceHero from '../../components/Industries/Ecommerce/EcommerceHero/EcommerceHero';
import EcommerceSolutions from '../../components/Industries/Ecommerce/EcommerceSolutions/EcommerceSolutions';
import IndustriesBusinessTypes from '../../components/Industries/IndustriesBusinessTypes/IndustriesBusinessTypes';
import IndustriesCarousel from '../../components/Industries/IndustriesCarousel/IndustriesCarousel';
import IndustriesIntegration from '../../components/Industries/IndustriesIntegration/IndustriesIntegration';
import IndustriesPaymentMethods from '../../components/Industries/IndustriesPaymentMethods/IndustriesPaymentMethods';
import { LocaleTypes } from '../../data/locales';

interface RetailPageProps {
  pathContext: {
    localeCode: LocaleTypes;
  };
  location: Location;
}

const RetailPage: React.FC<RetailPageProps> = ({ pathContext, location }) => {


  const content = () => (
    <>
      <SEO titleId="industries.ecommerce.title" />
      <EcommerceHero />

      <EcommerceFeatures />
      {/* <div style={{height: "200px"}}></div> */}
      <IndustriesBusinessTypes
        title="industries.ecommerce.business_types.title"
        subtitle="industries.ecommerce.business_types.subtitle"
        list={[
          'industries.ecommerce.business_types.list.type_1',
          'industries.ecommerce.business_types.list.type_2',
          'industries.ecommerce.business_types.list.type_3',
          'industries.ecommerce.business_types.list.type_4',
          'industries.ecommerce.business_types.list.type_5',
          'industries.ecommerce.business_types.list.type_6',
        ]}
      />

      <EcommerceSolutions />

      {/* <IndustriesPaymentMethods/> */}

      <IndustriesIntegration locale={pathContext.localeCode} />

      <IndustriesCarousel
        list={[
          'education',
          'tourism',
          'services',
        ]}
      />
      <Footer theme="primary" />
    </>
  );

  return <IndexLayout render={ content } locale={pathContext.localeCode} location={location} />
}

export default RetailPage;
