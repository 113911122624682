import React from 'react';
import { useIntl } from 'react-intl';
import IndustriesSolutions from '../../IndustriesSolutions/IndustriesSolutions';

import bgImg from './assets/images/bg.png';

const EcommerceSolutions: React.FC = () => {
  const intl = useIntl();

  return (
    <IndustriesSolutions
    title={intl.formatMessage({id: 'industries.ecommerce.solutions.title',})}
      bg={bgImg}
      solutions={[
        {
          description: 'industries.ecommerce.solutions.solution_1.subtitle',
          link: '/payments/card',
          link_text: 'industries.ecommerce.solutions.link',
          title: 'industries.ecommerce.solutions.solution_1.title',
        },
        {
          description: 'industries.ecommerce.solutions.solution_2.subtitle',
          link: '/payments/cash',
          link_text: 'industries.ecommerce.solutions.link',
          title: 'industries.ecommerce.solutions.solution_2.title',
        },
        {
          description: 'industries.ecommerce.solutions.solution_3.subtitle',
          link: '/payments/transfer',
          link_text: 'industries.ecommerce.solutions.link',
          title: 'industries.ecommerce.solutions.solution_3.title',
        },
      ]}
    />
  )
};

export default EcommerceSolutions;
